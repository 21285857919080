import React from 'react';
import Auth0ProviderWithNavigate from "./auth/auth0-provider-with-navigate";
import { createBrowserRouter, RouterProvider, Outlet, Route, createRoutesFromElements, redirect, useRouteError, isRouteErrorResponse } from "react-router-dom";
import { store, persistor } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

import App from "./App";
import "./App.css";
import Home from "./routes/Home";
import About from './routes/About';
import Account from './routes/Account';
import NotFound from './routes/NotFound';
import ProtectedComponent from './auth/protected-component';
import { AlertProvider } from './components/AlertContext';
import Blog, { blogLoader} from './routes/Blog';
import { discountLoader } from './routes/Discount';
import { couponLoader } from './routes/Coupon';
import { calcShipping } from './models/shipping';
import { LoadingProvider } from './components/LoadingContext';
import { addShipping } from './models/user/userCartSlice';
import BlogPage from './routes/BlogPage';
import ErrorPage from './routes/ErrorPage';
import Privacy from './routes/Privacy';
import Tips from './routes/Tips';
import Tip1 from './routes/Tip1';
import Tip2 from './routes/Tip2';
import Tip3 from './routes/Tip3';
import Tip4 from './routes/Tip4';
import Tip5 from './routes/Tip5';
import Tip6 from './routes/Tip6';
import Tip7 from './routes/Tip7';
import Returns from './routes/Returns';
import Shipping from './routes/Shipping';
import Plan from './routes/Plan';
import Services from './routes/Services';
import Tools from './routes/Tools';
import { listingLoader } from './routes/ListingEdit';
import { updateCart } from './models/user/userCartSlice';
import { locationCurrency } from './models/user/user';

const Update = React.lazy(() => import('./routes/Update'));
const ListingEdit = React.lazy(() => import('./routes/ListingEdit'));
const ConfigureModel = React.lazy(() => import('./routes/ConfigureModel'));
const BlogEdit = React.lazy(() => import('./routes/BlogEdit'));
const UpdateBlog = React.lazy(() => import('./routes/UpdateBlog'));
const OrderStatuses = React.lazy(() => import('./routes/OrderStatuses'));
const Orders = React.lazy(() => import('./routes/Orders'));
const Order = React.lazy(() => import('./routes/Order'));
const Discount = React.lazy(() => import('./routes/Discount'));
const Coupon = React.lazy(() => import('./routes/Coupon'));
const AddressPage = React.lazy(() => import('./routes/AddressPage'));


function RouteProvider() {

  const Auth0ProviderLayout = () => (
    <Auth0ProviderWithNavigate>
      <Outlet />
    </Auth0ProviderWithNavigate>
  );

  const AlertProviderLayout = () => (
    <AlertProvider >
      <Outlet />
    </AlertProvider>
  );

  const LoadingProviderLayout = () => {
    return (
      <LoadingProvider>
        <Outlet />
      </LoadingProvider>
    )
  };

  const ProviderLayout = () => {
    return (
      <Provider store={store} >
        <Outlet/>
      </Provider>
    )
  };

  const PersistGateLayout = () => {
    return (
      <PersistGate loading={null} persistor={persistor} >
        <Outlet/>
      </PersistGate>
    )
  };

  function ErrorBoundary() {
    const error = useRouteError();
    let message = "Something went wrong"
  
    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        message = "This page doesn't exist!";
      }
  
      if (error.status === 401) {
        message = "You aren't authorized to see this";
      }
  
      if (error.status === 503) {
        message = "Looks like our API is down";
      }
    }  
    return (<ErrorPage message={message}/>);
  }
  
  const convertAmazonAddress = (address, buyer) => {
    return {
      name: address.name,
      address1: address.addressLine1 ? address.addressLine1 : address.addressLine2,
      address2: address.addressLine1 ? address.addressLine2 : address.addressLine33,
      city: address.city,
      province: address.stateOrRegion,
      postalCode: address.postalCode,
      countryIso: address.countryCode, 
      email: buyer.email
    }
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<ProviderLayout />}>
      <Route element={<LoadingProviderLayout />}>
      <Route element={<PersistGateLayout />}>
      <Route element={<Auth0ProviderLayout />}>
        <Route element={<AlertProviderLayout />}>
            <Route 
              path="/"
              element={<App />}
              errorElement={<ErrorBoundary />}
            >
              <Route 
                index 
                element={<Home />}
                loader={async () => {
                  const response = await fetch("/api/caminonames");
                  return await response.json();
                }}
              />
              <Route 
                path="plan"
                element={<Plan />}
              />
              <Route 
                path="caminos/:camino_name" 
                lazy={() => import('./routes/Camino')}
                loader={async ({ params }) => {
                  const fieldList = '["_id","name","center"]'
                  const search = `?fields=${fieldList}`;
                  const response = await fetch(`/api/caminos/${params.camino_name}${search}`);
                  return await response.json();
                }}
              />
              <Route 
                path="caminolodging/:camino_name" 
                lazy={() => import('./routes/CaminoLodging')}
                loader={async ({ params }) => {
                  const response = await fetch(`/api/caminolodgings/${params.camino_name}`);
                  return await response.json();
                }}
              />
              <Route 
                path="caminos" 
                lazy={() => import('./routes/Caminos')}
                loader={async () => {
                  const fieldList = '["_id","name","color", "shortDescription"]'
                  const search = `?fields=${fieldList}`;
                  const response = await fetch(`/api/caminos${search}`);
                  return await response.json();
                }}
              />
              <Route 
                path=":camino_name/stages/:stage_number/:stage_id" 
                lazy={() => import('./routes/Stage')}
                loader={async ({ params }) => {                  
                  const stageResponse = await fetch(`/api/stages/${params.stage_id}`);
                  const stage = await stageResponse.json();
                  const fieldList = '["_id","name","kmlUri"]'
                  const search = `?fields=${fieldList}`;
                  const caminoResponse = await fetch(`/api/caminos/${params.camino_name}${search}`);
                  const { stages } = await caminoResponse.json();
                  const kmlSource = [{ id: stage._id, number: Number(params.stage_number), kmlUri: `${process.env.REACT_APP_SPACES_CDN_ENDPOINT}/kmls/${encodeURIComponent(stage.kmlUri)}`}];
                  return {...stage, stages , camino: params.camino_name, number: Number(params.stage_number), kmlSource}
                }}
              />
              <Route 
                path="lodging" 
                lazy={() => import('./routes/Lodging')}
                loader={async () => {
                  const fieldList = '["_id","name","color", "shortDescription"]'
                  const search = `?fields=${fieldList}`;
                  const caminoResponse = await fetch(`/api/caminos${search}`);
                  const caminos = await caminoResponse.json();
                  const townResponse = await fetch('/api/townsall')
                  const towns = await townResponse.json();
                  return { caminos, towns };
                }}
              />
              <Route 
                path="services" 
                element={<Services />}
              />
              <Route 
                path="store" 
                lazy={() => import('./routes/Store')}
              />
              <Route 
                path="store/:listing_id" 
                lazy={() => import('./routes/Listing')}
                loader={ async ({ params }) => {
                  const colorResponse = await fetch("/api/colors");
                  const allColors = await colorResponse.json();
                  const user = store.getState().usercart.user;
                  const cart = store.getState().usercart.cart;
                  const processingDate = store.getState().usercart.processingDate;
                  const countryIso = cart.shippingAddress?.countryIso || user.countryIso || "US";
                  const response = await fetch(`/api/listings/${params.listing_id}`);
                  if (response) {
                    const {listing, similar} = await response.json();
                    const { region, toCountry, fromCountry, shippingPrice, minTime, maxTime } =
                    calcShipping({countryIso: countryIso, listing: listing, quantity: 1, rate: user.rate, processingDate: processingDate});
                    return { listing, similar, user, region, toCountry, fromCountry, shippingPrice, minTime, maxTime, allColors }
                  }
                }}
              />
              <Route 
                path="cart" 
                lazy={() => import('./routes/Cart')}
                loader={ async ({ request, params }) => {
                  let cart = store.getState().usercart.cart;
                  if (!cart || cart.products.length === 0) {
                    return redirect("/");
                  } else { 
                    const discountResponse = await fetch("/api/discounts/active");
                    const discounts = await discountResponse.json();
                    const query = new URL(request.url).searchParams;
                    const state = query.get("state");
                    const amazonId = query.get("amazonCheckoutSessionId");
                    if (state === "saveShipping") {
                      store.dispatch(addShipping(cart.shippingAddress));
                    }
                    if (amazonId) {
                      // update cart with data from Amazon
                      const amazonResponse = await fetch(`/api/getAmazonDetails/${amazonId}`);
                      const amazonDetails = await amazonResponse.json();
                      if (amazonDetails.billingAddress) {
                        store.dispatch(updateCart({shippingAddress: convertAmazonAddress(amazonDetails.shippingAddress, amazonDetails.buyer), billingAddress: convertAmazonAddress(amazonDetails.billingAddress, amazonDetails.buyer)}));
                        return { discounts: discounts, amazonDetails: amazonDetails}
                      } else {
                        return redirect("/cart");
                      }
                      
                    }
                    return { discounts: discounts };
                  }
                }}
              />
              <Route 
                path="cart/finalize" 
                lazy={() => import('./routes/Cart')}
                loader={ async ({ request, params }) => {
                  const cart = store.getState().usercart.cart;
                  const discountResponse = await fetch("/api/discounts/active");
                  const discounts = await discountResponse.json();
                  const query = new URL(request.url).searchParams;
                  const amazonId = query.get("amazonCheckoutSessionId");
                  if (amazonId) {
                    const { currency, rate } = await locationCurrency(cart.shippingAddress);
                    const payload = {
                      chargeAmount: {
                        amount: cart.paymentAmount,
                        currencyCode: currency
                      }
                    };
                    const amazonResponse = await fetch(`/api/completeAmazonCheckout/${amazonId}`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(payload),
                    });
                    const amazonDetails = await amazonResponse.json();
                    console.log(amazonDetails); 
                    return { discounts: discounts, amazonDetails: amazonDetails}
                  } else {
                    return redirect("/");
                  }
                }}
              />
              <Route 
                path="addresses" 
                element={<ProtectedComponent component={AddressPage} role="user" userId={store.getState().usercart.user._id} />}
              />
              <Route 
                path="blogs" 
                element={<Blog />}
                loader={blogLoader}
              />
              <Route 
                path="blogs/edit/:blog_date" 
                element={<ProtectedComponent component={BlogEdit} role="admin" />}
                loader={ async ({ params }) => {
                  let response = await fetch(`/api/blogs/${params.blog_date}`);
                  const blog = await response.json();
                  response = await fetch("/api/blogs");
                  const blogs = await response.json();
                  response = await fetch("/api/blogcategories");
                  const blogCategories = await response.json();
                  return { blog, blogs, blogCategories };
                }}
              />
              <Route 
                path="blogs/:blog_date" 
                element={<BlogPage />}
                loader={ async ({ params }) => {
                  const response = await fetch(`/api/blogs/${params.blog_date}`);
                  return await response.json();
                 }}
              />
              <Route path="about" element={<About />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="returns" element={<Returns />} />
              <Route path="shipping" element={<Shipping />} />
              <Route path="tips" element={<Tips />} />
              <Route path="tips/1" element={<Tip1 />} />
              <Route path="tips/2" element={<Tip2 />} />
              <Route path="tips/3" element={<Tip3 />} />
              <Route path="tips/4" element={<Tip4 />} />
              <Route path="tips/5" element={<Tip5 />} />
              <Route path="tips/6" element={<Tip6 />} />
              <Route path="tips/7" element={<Tip7 />} />
              <Route path="tools" 
                element={<Tools />} 
                loader={async () => {
                  const fieldList = '["_id","name","color", "shortDescription"]'
                  const search = `?fields=${fieldList}`;
                  const response = await fetch(`/api/caminos${search}`);
                  return await response.json();
                }}
              />
              <Route path="account" element={
                <ProtectedComponent 
                  component={Account}
                  role="user" 
                  userId={store.getState().usercart.user._id}
                />}/>
              <Route 
                path="admin/maintenance" 
                element={<ProtectedComponent component={Update} role="admin"/>}
                loader={ async () => {
                  const response = await fetch("/api/caminonames");
                  const caminos = await response.json();
                  return caminos.map(camino => camino.name); 
                  }
                }
              />
              <Route path="admin/updateblogs" element={<ProtectedComponent component={UpdateBlog} role="admin"/>}/>
              <Route 
                path="admin/discounts" 
                element={<ProtectedComponent component={Discount} role="admin"/>}
                loader={discountLoader}
              />
              <Route 
                path="admin/listings" 
                element={<ProtectedComponent component={ListingEdit} role="admin"/>}
                loader={listingLoader}
              />
              <Route 
                path="admin/listings/model/:listing_id" 
                element={<ProtectedComponent component={ConfigureModel} role="admin"/>}
                loader={ async ({ params }) => {
                  const colorResponse = await fetch("/api/colors");
                  const colors = await colorResponse.json();
                  let colorOptions = {};
                  for (const color of colors) {
                    colorOptions[color.label] = color.value;
                  };
                  const response = await fetch(`/api/listings/${params.listing_id}`);
                  if (response) {
                    const { listing } = await response.json();
                    return { listing, colors, colorOptions }
                  }
                }}
              />
              <Route 
                path="admin/coupons" 
                element={<ProtectedComponent component={Coupon} role="admin"/>}
                loader={couponLoader}
              />
              <Route 
                path="admin/orders" 
                element={<ProtectedComponent component={OrderStatuses} role="admin"/>}
                loader={async ({ params }) => {
                  const response = await fetch("/api/allinvoices");                
                  return await response.json();
                }}
              />            
              <Route 
                path="admin/orders/pending" 
                element={<ProtectedComponent component={OrderStatuses} role="admin"/>}
                loader={async ({ params }) => {
                  const response = await fetch("/api/openinvoices");                
                  return await response.json();
                }}
              />            
              <Route 
                path="/orders/:user_id"
                element={<Orders />}
                loader={async ({ params }) => {
                  const response = await fetch(`/api/orders/${params.user_id}`);                
                  return await response.json();
                }}
              />
              <Route 
                path="/order/:invoice_id"
                element={<Order />}
                loader={async ({ params }) => {
                  const invoiceResponse = await fetch(`/api/invoices/${params.invoice_id}`);                
                  const order =  await invoiceResponse.json();
                  const productIds = order.products.map(object => object.product._id);                 
                  const productResponse = await fetch(`/api/products?products=${JSON.stringify(productIds)}`);
                  const products = await productResponse.json();
                  const listingIds = products.map(product => product.listing);
                  const listingsResponse = await fetch(`/api/listings?listings=${JSON.stringify(listingIds)}`);
                  const listings =  await listingsResponse.json();
                  return {order, products, listings}
                }}
              />
              <Route 
                path="/orderbyid/:order_id"
                element={<Order />}
                loader={async ({ params }) => {
                  const invoiceResponse = await fetch(`/api/order/${params.order_id}`);
                  if (invoiceResponse.status === 200) {
                    const order =  await invoiceResponse.json();
                    const productIds = order.products.map(object => object.product._id);
                    const productResponse = await fetch(`/api/products?products=${JSON.stringify(productIds)}`);
                    const products = await productResponse.json();
                    const listingIds = products.map(product => product.listing);
                    const listingsResponse = await fetch(`/api/listings?listings=${JSON.stringify(listingIds)}`);
                    const listings =  await listingsResponse.json();
                    return {order, products, listings}
                  } else {
                    return redirect("/");
                  }
                }}
              />
              <Route
                path="*"
                element={<NotFound />}        
              />
            </Route>
        </Route>
      </Route>
      </Route>
      </Route>
      </Route>
    )
  );
  return (
    <RouterProvider router={router} />
  )

}

export default RouteProvider;


