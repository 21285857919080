import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { 
  updateUser,
  getIp,
  getCurrencyFromIso,
 } from "./user";

 import {
  updateSavedCart,
  newCart
 } from "../cart/cart"

const initialState = {
  user: {name: "Guest", countryIso: "US", currency: "USD", rate: 1},
  cart: {products: [], status: "cart"},
  cartclash: false,
  processingDate: new Date(),
  status: "idle",  //"idle", "loading", "succeeded", "failed"
  error: null,
};

export const createGuest = createAsyncThunk("user/createGuest", async () => { 
  // NEED TO ADD A TIMEOUT
  const iso = await getIp();

  // get currency and today's conversion rate
  const { currency, rate } = await getCurrencyFromIso(iso);
  return {countryIso: iso, currency: currency, rate: rate};
});

export const fetchUser = createAsyncThunk("user/fetchUser", async (user, {dispatch}) => { 
  // user is provided by Auth0 
  const user_id = user.sub;
  try {
    const response = await fetch(`/api/user/${user_id}`);
    if (response.status === 500) {
      // user not in db - add a new one
      const user_id = user.sub;
      const location = await getIp();
      const newUser = {
        user_id: user_id,
        status: "active",
        name: user.name,
        email: user.email,
        role: "basic",
        countryIso: location,
        picture: user.picture,
        likes: []
      }
      try {
        const result = await fetch("/api/user", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(newUser)
        });  
        return result.json();
      } catch (err) {
        return err.message;
      }
    } else {
      let user = await response.json();
      if (!user.countryIso) {
        const iso = await getIp();
        user.countryIso = iso;
      }
      const { currency, rate } = await getCurrencyFromIso(user.countryIso);
      user.currency = currency;
      user.rate = rate;
      if (user.cart) {
        dispatch(fetchCart(user.cart))
      }
      return user;
    }
  } catch (err) {
    return err.message;
  }
});

export const fetchCart = createAsyncThunk("cart/fetchCart", async(cartId, {getState}) => {
  if (cartId) {
    const state = getState();
    const cart = state.usercart.cart;
    if (cart.products?.length > 0 && (!cart._id || cart._id !== cartId)) {
      // conflict between cart in state and in db
      return {cartclash: true}
    } else {
      const response = await fetch(`/api/invoices/${cartId}`);
      const savedCart = await response.json();
      return {cart: savedCart, cartclash: false};  
    }
  }
});

export const fetchProcessingDate = createAsyncThunk("fetchProcessingDate", async() => {
  console.log("fetch proceesing datae called")
  const response = await fetch("/api/processingdate");
  const processingDate = await response.json();
  return { processingDate: processingDate };
});

export const addShipping = createAsyncThunk("user/addShipping", async (address, { getState }) => {
  const state = getState();
  const user = state.usercart.user;
  if (!user.shippingAddresses || user.shippingAddresses.length === 0 || !user.shippingAddresses.find(item => 
    item.name === address.name &&
    item.address1 === address.address1 &&
    item.address2 === address.address2 &&
    item.city === address.city &&
    item.postalCode === address.postalCode &&
    item.email === address.email)) 
    {
      const countryIso = user.shippingAddresses[0]?.countryIso || address.countryIso;
      const { currency, rate } = await getCurrencyFromIso(countryIso);
      const data = {
        countryIso: countryIso,
        shippingAddresses: [...user.shippingAddresses, address],
      }     
    try {
      const adjustedUser = await updateUser(user._id, data);     
      return {...adjustedUser, currency: currency, rate: rate, };
    } catch (err) {
      return err.message;
    }
  } else {
    // nothing to add
    return null
  }
})

export const editShipping = createAsyncThunk("user/editShipping", async ([index, address], { getState }) => {
  const state = getState();
  const user = state.usercart.user;
  if (user.shippingAddresses[index]) {
    let shippingAddresses = [...user.shippingAddresses];
    shippingAddresses[index] = address;
    let countryIso = user.countryIso;
    if (index === 0) {
      countryIso = address.countryIso;
    } 
    const { currency, rate } = await getCurrencyFromIso(countryIso);
    const data = {
      countryIso: countryIso,
      shippingAddresses: shippingAddresses
    } 
    const adjustedUser = await updateUser(user._id, data);     
    return {...adjustedUser, currency: currency, rate: rate, };
  }
})

export const deleteShipping = createAsyncThunk("user/deleteShipping", async (index, { getState }) => {
  const state = getState();
  const user = state.usercart.user;
  if (user.shippingAddresses[index] && user.shippingAddresses.length > 1) {
    let shippingAddresses = [...user.shippingAddresses];
    shippingAddresses.splice(index, 1);
    let countryIso = user.countryIso;
    if (index === 0) {
      countryIso = shippingAddresses[0].countryIso;
    }
    const { currency, rate } = await getCurrencyFromIso(countryIso);
    const data = {
      countryIso: countryIso,
      shippingAddresses: shippingAddresses
    } 
    const adjustedUser = await updateUser(user._id, data);     
    return {...adjustedUser, currency: currency, rate: rate, };
  }
});


export const makeShippingDefault = createAsyncThunk("user/makeShippingDefault", async (index, { getState }) => {  
  const state = getState();
  const user = state.usercart.user;
  if (user.shippingAddresses[index] && user.shippingAddresses.length > 1) {
    let shippingAddresses = [...user.shippingAddresses];
    shippingAddresses.unshift(shippingAddresses.splice(index, 1)[0]);
    const { currency, rate } = await getCurrencyFromIso(shippingAddresses[0].countryIso);
    const data = {
      countryIso: shippingAddresses[0].countryIso,
      shippingAddresses: shippingAddresses};
    const adjustedUser = await updateUser(user._id, data);     
    return {...adjustedUser, currency: currency, rate: rate, };
  }
})

export const updateCart = createAsyncThunk("cart/updateCart", async (data, { getState }) => {
  console.log("update cart")
  const state = getState();
  const user = state.usercart.user;
  const cart = state.usercart.cart;
  if (cart._id) {
    // update existing cart in db
    return updateSavedCart(cart._id, data);  
  } else if (user._id && cart.products.length > 0) {
    // create a new cart in db and update
    data = {
      ...cart,
      ...data,
      status: "cart",
      user: user._id,
    }
    return newCart(data);
    } else {
      // just update cart in memory
      return {...cart, ...data}
    }  
})

export const finalizeCart = createAsyncThunk("cart/finalizeCart", async (data, { getState }) => {
  const state = getState();
  const user = state.usercart.user;
  const cart = state.usercart.cart;
  let savedCart;
  if (cart._id) {
    savedCart = await updateSavedCart(cart._id, data);
    await updateUser(user._id, {cart: null})
    await fetch(`/api/printify/orders/create/${cart._id}`);
  } else {
    const update = {...cart, ...data}
    savedCart = await newCart(update);
    await fetch(`/api/printify/orders/create/${savedCart._id}`);
  }
  return savedCart;
})

export const addToCart = createAsyncThunk("cart/addToCart", async(props, { getState }) => {
  const state = getState();
  const user = state.usercart.user;
  const cart = state.usercart.cart;
  const { qty, product, price, listing, personalized, instructions } = props

  // if user is logged in then add to or create an invoice to hold cart info
  // otherwise, save to localCart only

  if (user._id) {
    if (user.cart) {
      const products = [...cart.products, { product: product, personalized: personalized, instructions: instructions, listing: listing, qty: qty, price: price, status: "pending" }];
      const data = {
        products: products
      }
      return updateSavedCart(user.cart, data);
    } else {
      const data = {
        status: "cart",
        user: user._id,
        products: [{ product: product, listing: listing, personalized: personalized, instructions: instructions, qty: qty, price: price, status: "pending" }]
      }
      return newCart(data);
    }
  } else {
    const ObjectID = require("bson-objectid");  
    product['_id'] = ObjectID().toString();  
    const products = [...cart.products];
    products.push({ product: product, listing: listing, personalized: personalized, instructions: instructions, qty: qty, price: price });
    return {...cart, products: products};
  }
});

export const deleteCart = createAsyncThunk("cart/deleteCart", async (params, {getState}) => {
  const state = getState();
  const cartId = state.usercart.cart._id;
  if (cartId) {
    await fetch(`/api/invoice/delete/${cartId}`);
  }
});

export const replaceSavedCart = createAsyncThunk("cart/replaceSavedCart", async (cartId, {getState}) => {
  const state = getState();
  const cart = state.usercart.cart;
  const data = {...cart}
  return updateSavedCart(cartId, data);  
});

export const combineCarts = createAsyncThunk("cart/combineCarts", async (param, {getState}) => {
  const state = getState();
  const user = state.usercart.user;
  const cart = state.usercart.cart;
  const response = await fetch(`/api/invoices/${user.cart}`);
  let savedCart = await response.json();
  const products = savedCart.products.concat(cart.products);
  const data = {products: products};
  return await updateSavedCart(user.cart, data);
});

export const resolveCartConflict = createAsyncThunk("cart/resolveCartConflict", async (solution, {getState, dispatch}) => {
  const state = getState();
  const user = state.usercart.user;
  switch(solution) {
    case "saved":
      dispatch(resetCart());
      dispatch(fetchCart(user.cart))
      break;
    case "open":
      dispatch(replaceSavedCart(user.cart))
      break;
    case "combine":
      dispatch(combineCarts())
      break;
    default:      
  }
})

const userCartSlice = createSlice({
  name: "userCart",
  initialState,
  reducers: {
    setUser: {
      reducer(state, action) {
        state.user = action.payload
      },
    },
    resetCart: {
      reducer(state, action) {
        state.cart = {products: [], status: "cart"}
      },
    },
    resetAll: {
      reducer(state, action) {
        state.cart = {products: [], status: "cart"}
        state.user = {name: "Guest", countryIso: state.user.countryIso, currency: state.user.currency, rate: state.user.rate};
      },
    },
  },
  extraReducers(builder) {
    builder
    .addCase(fetchUser.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(fetchUser.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.user = action.payload;
    })
    .addCase(fetchUser.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(addShipping.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload;
      }
    })
    .addCase(addShipping.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(deleteShipping.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload;
      }
    })
    .addCase(deleteShipping.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(editShipping.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload;
      }
    })
    .addCase(editShipping.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(makeShippingDefault.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload;
      }
    })
    .addCase(makeShippingDefault.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(createGuest.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(createGuest.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = 'succeeded'
        state.user = {name: "Guest", ...action.payload};
      }
    })
    .addCase(addToCart.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(addToCart.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.cart = action.payload;
      state.user.cart = action.payload._id;
      if (state.user.name !== "Guest") {
        updateUser(state.user._id, {cart: action.payload._id})
      }
    })
    .addCase(addToCart.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(fetchCart.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(fetchCart.fulfilled, (state, action) => {
      state.status = 'succeeded'
      if (action.payload.cartclash === true) {
        state.cartclash = true;
      } else {
        state.cartclash = false;
        state.cart = action.payload.cart;
      }
    })
    .addCase(fetchCart.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(resolveCartConflict.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(resolveCartConflict.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.cartclash = false;
    })
    .addCase(resolveCartConflict.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(deleteCart.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(deleteCart.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.cartclash = false
      state.cart =  {products: [], status: "cart"}
      if (state.user.name !== "Guest") {
        state.user.cart = null;
        updateUser(state.user._id, {cart: null});
      }
    })
    .addCase(deleteCart.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(combineCarts.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(combineCarts.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.cart = action.payload;
    })
    .addCase(combineCarts.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(updateCart.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(updateCart.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.cart = action.payload
    })
    .addCase(updateCart.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(replaceSavedCart.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(replaceSavedCart.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.cart = action.payload
    })
    .addCase(replaceSavedCart.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(finalizeCart.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(finalizeCart.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.cart = {products: []}
      state.user.cart = null
    })
    .addCase(finalizeCart.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
    .addCase(fetchProcessingDate.pending, (state, action) => {
      state.status = "loading"
    })
    .addCase(fetchProcessingDate.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.processingDate = action.payload.processingDate
    })
    .addCase(fetchProcessingDate.rejected, (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    })
  }
});

  export const getUser = (state) => {
    return state.usercart.user;
  }

  export const getCart = (state) => state.usercart.cart;

  export const getStatus = (state) => state.usercart.status;

  export const getCartClash = (state) => state.usercart.cartclash;

  export const getProcessingDate = (state) => state.usercart.processingDate;

  export const { setUser, resetCart, resetAll } = userCartSlice.actions;

  export default userCartSlice.reducer;

